import React, { useState } from "react";
import { useFormik } from "formik";
import { Button, Modal, Spinner } from 'react-bootstrap';
import MainLogo from "../../components/logos/MainLogo";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useRecoveryPasswordMutation } from "../../redux/queryAPI/customerWebsiteAPI";
import * as Yup from "yup";

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const ForgotPassword = () => {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [, setResult] = useState(null);

    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
    const handleCloseErrorModal = () => setShowErrorModal(false);
    const handleShowErrorModal = () => setShowErrorModal(true);

    const [recoverPassword] = useRecoveryPasswordMutation();

    const onSubmit = async (values) => {
        setIsLoading(true);

        try {
            const response = await recoverPassword(values.email).unwrap();
            setResult(response);
            console.log(response.message);

            if (response.message) {
                handleShowSuccessModal();
            } else if (response.isError) {
                handleShowErrorModal();
            }
        } catch (error) {
            console.log(error.data.message);
            handleShowErrorModal();
        }

        setIsLoading(false);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit,
    });

    const { errors, touched } = formik;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="main-container d-flex align-items-center justify-content-center">
                <div className="login-box">
                    <div className="login-logo">
                        <MainLogo />
                    </div>
                    <div className="login-box-body">
                        <h1 className="text-center mb-3 font-weight-600">{t('Forgot_Password')}</h1>
                        <p className="text-center mb-4">{t('Enter_your_registered_email')}</p>
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t('Email')}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.email}
                                       onChange={formik.handleChange}
                                       name="email" id="email" />
                                {errors.email && touched.email && (
                                    <small className="text-danger">{errors.email}</small>
                                )}
                            </div>
                            <button type="button" className="btn btn-primary btn-block btn-lg"
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}>
                                {isLoading ? (
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                ) : (
                                    t('Submit')
                                )}
                            </button>
                        </form>
                    </div>
                    <div className="login-box-footer text-center">
                        {t('Remember_Password')} <Link className="text-primary-custom" to="/"> {t('SignIn')}</Link>
                    </div>
                </div>
            </div>
            <Modal centered show={showSuccessModal} onHide={handleCloseSuccessModal} className="warning-modal" animation={false}>
                <Modal.Header className="align-items-center">
                    <h3 className="modal-title">Password Recovery</h3>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-default font-size-12 m-0">An email has been sent to your registered email ID
                        containing the password reset link along with a list of instructions. Please follow the steps
                        mentioned in the email to reset your password.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button variant="primary" onClick={handleCloseSuccessModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showErrorModal} onHide={handleCloseErrorModal} className="error-modal" animation={true}>
                <Modal.Header className="align-items-center">
                    <h3 className="modal-title">Password Recovery Error</h3>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-danger font-size-12 m-0">There was an error recovering your password. Please contact with the site administration</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button variant="primary" onClick={handleCloseErrorModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ForgotPassword;
