import React,{useState,useEffect} from "react";
import { Card } from "react-bootstrap";
//redux
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { companyPhoneNumber} from "../../redux/action";
import {useTranslation} from "react-i18next";

const NeedHelpBox = () => {
  const[number,setNumber] = useState('');
  const phNo = useSelector((state) => state?.userDataReducer?.phnumb);
  const { t } = useTranslation()
  useEffect(() => {
    dispatch(companyPhoneNumber());
  }, [])

  useEffect(() => {
      if(phNo){
        setNumber(phNo)
      }
  }, [phNo])
  return (
    <React.Fragment>
      <Card className='bg-primary text-center mt-auto need-help-box'>
          <Card.Body>
              <h2 className='text-white'>{t('Do_you_need_help?')}</h2>
              <h4 className='text-white'>{t('Contact_us')}<br />
                  <span>{number}</span>
              </h4>
          </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default NeedHelpBox;
