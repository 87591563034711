import {apiCall, METHOD} from "../../service/baseApiCall";
import {NOTIFICATIONCMP, NOTIFICATIONLIST, CHECKNOTIFICATION} from "../constants/action-types";

//Home Notification
export const getNotificationsColumn = () => (dispatch) => {
    dispatch(NotificationCmpInit());
};

export const NotificationCmpInit = () => (dispatch) => {
    dispatch({
        type: NOTIFICATIONCMP.NOTIFICATIONCMP_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `notification/preview`,
        {
        },
        (res) => dispatch(NotificationCmpSuccess(res)),
        () =>  dispatch(NotificationCmpError()) ,
        METHOD.GET,
        {}
    );
};

export const NotificationCmpSuccess = (res) => (dispatch) => {
    dispatch({
        type: NOTIFICATIONCMP.NOTIFICATIONCMP_SUCCESS ,
        payload: res?.data
    });
};

export const NotificationCmpError = () => (dispatch) => {
    dispatch({
        type: NOTIFICATIONCMP.NOTIFICATIONCMP_ERORR
    });
};

//Upload Notification
export const checkNotification = (id) => (dispatch) => {
    dispatch(checkNotificationInit(id));
};

export const checkNotificationInit = (id) => (dispatch) => {
    dispatch({type: CHECKNOTIFICATION.CHECKNOTIFICATION_INITZIALITZATION});
    apiCall(process.env.REACT_APP_BASE_API_CALL + `notification/check/${id}`,
        {},
        (res) => dispatch(checkNotificationSuccess(res)),
        () => dispatch(checkNotificationError()),
        METHOD.PUT,
        {}
    );
};

export const checkNotificationSuccess = (res) => (dispatch) => {
    dispatch({
        type: CHECKNOTIFICATION.CHECKNOTIFICATION_SUCCESS,
        payload: res?.data
    });
};

export const checkNotificationError = () => (dispatch) => {
    dispatch({
        type: CHECKNOTIFICATION.CHECKNOTIFICATION_ERROR
    });
};

//Notification List
export const NotificationList = (bData,text) => (dispatch) => {
    dispatch(NotificationListInit(bData,text));
};

export const NotificationListInit = (bData,text) => (dispatch) => {
    var values= Object.entries(text);

    if(text){
        // console.log(text,"sh");
    }
    dispatch({
        type: NOTIFICATIONLIST.NOTIFICATIONLIST_INITLIZATION
    });

    let url = new URL(process.env.REACT_APP_BASE_API_CALL + `notification/list?isactionrequired=${bData}`);
    if(values){
        values.forEach((i)=>{
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }
    apiCall(
        url,
        {
        },
        (res) => dispatch(NotificationListSuccess(res)),
        () =>  dispatch(NotificationListError()) ,
        METHOD.GET,
        {}
    );
};

export const NotificationListSuccess = (res) => (dispatch) => {
    dispatch({
        type: NOTIFICATIONLIST.NOTIFICATIONLIST_SUCCESS ,
        payload: res?.data
    });
};

export const NotificationListError = () => (dispatch) => {
    dispatch({
        type: NOTIFICATIONLIST.NOTIFICATIONLIST_ERORR
    });
};