import {
  Home,
  SignIn,
  SeaFright,
  Invoice,
  Quotation,
  QuotationInfo,
  Notifications,
  NotificationsCreate,
  Settings,
  ForgotPassword,
  Register
} from '../pages';
import { ResetPassword } from '../pages/sign-in/ResetPassword';
import { Wip } from '../pages/WIP/WIP';

export const publicRoutes = [
  {
    exact: true,
    path: "/",
    component: SignIn,
  },
  {
    exact: true,
    path: "/sign-in",
    component: SignIn,
  }, 
  {
    exact: true,
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    exact: true,
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    exact: true,
    path:"/register",
    component: Register,
  }
];

export const privateRoutes = [
  {
    exact: true,
    path: "/home",
    component: Home,
  },
  {
    exact: true,
    path: "/seafright/:Id",
    // path: "/shipmentDetails/:Id",
    component: SeaFright,
  },
  {
    exact: true,
    path: "/invoices",
    component: Invoice,
  },
  {
    exact: true,
    path: "/quotations",
    component: Quotation,
  },
  {
    exact: true,
    path: "/quotations/info",
    component: QuotationInfo,
  },
  {
    exact: true,
    path: "/file-manager",
    component: Wip,
  },
  {
    exact: true,
    path: "/reports",
    component: Wip,
  },
  {
    exact: true,
    path: "/notifications",
    component: Notifications,
  },
  {
    exact: true,
    path: "/notifications/create",
    component: NotificationsCreate,
  },
  {
    exact: true,
    path: "/settings",
    component: Settings,
  },
  {
    exact: true,
    path: "/exit",
    component: SignIn,
  },
]; 
