import axios from "axios";
import {toast} from "react-toastify";
import {getToken, validateToken} from "../../src/utils";
import {dispatch} from "../redux";
import {logout} from "../redux/action";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(req => {
    if (validateToken()) {
        const token = getToken("login-auth-token");
        // const validatedToke = valiadteToken();
        const tk = token.replace(/"/g, ' ');
        const auth = tk ? `Bearer ${tk}` : '';
        req.headers.common['Authorization'] = auth;
    }
    return req;
});

instance.interceptors.response.use(res => {
    try {
        if (res.status === 200 || res.status === 204) {
            return res;
        }
    } catch (err) {
        toast.error(res.data.data.message);
    }
    return res;
}, error => {
    try {
        if (error.response.status === 401 || error.response.status === 403) {
            dispatch(logout())
        }
    } catch (err) {
        toast.error(error.data.data.message);
    }

});

export default instance;
