import React from "react";
import { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form, Table } from "react-bootstrap";
import { ReactComponent as BackIcon } from '../../assets/img/back.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import User from "../../assets/img/user.svg";
import Trash from "../../assets/img/trash.svg";
import { ReactComponent as SearchIcon } from '../../assets/img/search-icon.svg';
// import PaginationComp from "../../components/pagination/PaginationComp";
import WarningDlt from "../../modals/WarningDlt";

import { dispatch } from "../../redux";
import { useSelector } from "react-redux";
import { companyUsers } from "../../redux/action";
import PaginationComp from "../pagination/PaginationComp";
const ChangePermission = (Props) => {
    
  const [sessionsPerPage, ] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const[userId,setUserId] =useState();
    const [cList,setClist] = useState([]);
    const cListData= useSelector(
        (state) => state?.userDataReducer?.cmpUserList
    );

    const allSessionsCount = cList?.length; 
    const lastSessionNumber = currentPage * sessionsPerPage;
    const firstSessionIndex = lastSessionNumber - sessionsPerPage;
    const limitedSessions = cList?.slice(
      firstSessionIndex,
      lastSessionNumber
    );
    useEffect(()=>{
        if(Props.cmpId){
            dispatch(companyUsers(Props.cmpId));

        }
    },[Props.cmpId])

    useEffect(() => {
        if (cListData) {
            setClist(cListData);
        }
      }, [cListData]);

  const [showM, setShowM] = useState(false);

    const handleShowM = () => setShowM(true);
    const handleCallback = () => setShowM(false);
const handleCallModal =(id)=>{
    setUserId(id);
    handleShowM();
}
    
  return (
    <React.Fragment>
        <Card className={ Props?.Show}>
            <Card.Body className= "px-lg-4 pt-lg-4">
            <span  onClick={Props.handleGoBack} className="mb-3 d-block pointer">
            <BackIcon className="back-icon" /> Go Back
            </span>
             
                <Row className='mb-2 mb-lg-4 align-items-center'>
                    <Col md={4} xl={3}>
                        <div className='table-top-search'>
                            <SearchIcon className='icon' />
                            <Form.Control type="text" placeholder="Search..." />
                        </div>
                    </Col>
                    <Col lg={12} xl={9} className='ml-auto text-right mt-2 mt-xl-0'>
                        <Dropdown className="btn-group mr-2">  
                            <Dropdown.Toggle variant="secondary" className="text-primary-custom mb-2 mb-sm-0">
                              Add User
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-385 p-0">
                                <div className="p-4">
                                    <h4 className="mb-3">Add User</h4>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="font-size-13 font-weight-bold">User Emails</Form.Label>
                                        <Form.Control as="textarea" placeholder="Enter on or more email address" rows={3} />
                                    </Form.Group>
                                    <small className="font-size-13 font-weight-bold text-black">Users will receive an invitation email.</small>
                                    <div className="mt-4">
                                        <Button type="button" variant="primary" className="mr-2">Add</Button>
                                        <Button type="button" variant="secondary">Cancel</Button>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                     </Col>
                </Row>
                <Table responsive borderless hover>
                    <thead>
                        <tr>
                            <th className="w-50"></th>
                            <th>Name </th>
                            <th>Status</th>
                            <th>Last Connection </th>
                            <th className="table-field-actions">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                    {limitedSessions &&
                        limitedSessions?.map((item) => (
                                    <tr key={item.id} >
                            <td><img src={User} alt="User" /></td>
                            {/* <td>{item?.firstName}{" "}{item?.lastName}</td> */}
                            <td>{item?.name}</td>
                            <td>{item?.status}</td>
                            <td>{item?.lastConnexion}</td>
                            <td className="table-field-actions" onClick={(e)=>handleCallModal(item.id)}>
                                <Button type="button" variant="link" className="text-decoration-none" ><img src={Trash} alt="Trash" /></Button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
                <PaginationComp
                    itemsCount={allSessionsCount} 
                    itemsPerPage={sessionsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                  />
            </Card.Body>
        </Card>
       <WarningDlt showData={showM} parentCallback = {handleCallback} id={userId} cmpId={Props.cmpId}/>
    </React.Fragment>
  );
};

export default ChangePermission;
