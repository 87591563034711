/* eslint-disable no-case-declarations */
import {
  LOGIN,
  PROFILEIMG,
  LOGOIMG,
  USER,
  LOGOUT,
  COMPANYPROFILE,
  COMPANYLIST,
  COMPANYUSERS,
  USERSDETAILS,
  UPDATEUSERS,
  ORGANIZATIONDROPDOWN,
  SENDINVITATION,
  REGISTER_USER,
  DELETE_ORG_USER,
  MODIFY_USER,
  COMPANY_PHNO,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  NOTIFICATION_UPLOAD,
  NOTIFICATION_DETAILS,
  UPDATECOMPANY
} from "../constants/action-types";
// import { UserData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getToken } from "../../utils";
// import { ActionTypes } from "./Actions";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  isLoggedin: JSON.parse(getToken("isLoggedIn") || 'false'),
  isLoggedout: false,
  authToken: '',
  profileImg: "",
  logoImg: "",
  user: {},
  CompanyList: [],
  cmpUserList: [],
  orgdwdnData: {},
  phnumb: '',
  resData: '',
  Notdata:{},
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] }, (state = initialState, action) => {

    switch (action.type) {
      case LOGIN.LOGIN_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          isLoggedout: false,
          progress: true,
        };
      case LOGIN.LOGIN_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoggedin: true,
          isLoggedout: false,
          isLoading: false,
          progress: true
        };

      case LOGIN.LOGIN_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoggedin: false,
          isLoading: false,
          progress: false
        };

      case LOGOUT.LOGOUT_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true
        };
      case LOGOUT.LOGOUT_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoggedout: true,
          isLoggedin: false,
          isLoading: false
        };
      case LOGOUT.LOGOUT_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoggedout: false,
          isLoading: false
        };
      //user profile
      case USER.USER_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case USER.USER_SUCCESS:
        const user = action.payload;

        return {
          ...state,
          ...action.payload,
          // isLoggedin:true,
          isLoggedout: false,
          isLoading: false,
          user: user,
          progress: true

        };

      case USER.USER_ERORR:
        return {
          ...state,
          ...action.payload,
          // isLoggedin: false,
          isLoggedout: false,
          isLoading: false,
          progress: false
        };

      //company user profile
      case COMPANYPROFILE.COMPANYPROFILE_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case COMPANYPROFILE.COMPANYPROFILE_SUCCESS:
        const companyProfileData = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          CompanyProfile: companyProfileData,
          progress: true

        };

      case COMPANYPROFILE.COMPANYPROFILE_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };

      //company user profile
      case COMPANYLIST.COMPANYLIST_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case COMPANYLIST.COMPANYLIST_SUCCESS:
        const companyList = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          CompanyList: companyList,
          progress: true

        };

      case COMPANYLIST.COMPANYLIST_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };

      //company user list
      case COMPANYUSERS.COMPANYUSERS_INITIALIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case COMPANYUSERS.COMPANYUSERS_SUCCESS:
        const cData = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          cmpUserList: cData,
          progress: true

        };

      case COMPANYUSERS.COMPANYUSERS_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      //Update Users
      case UPDATEUSERS.UPDATEUSERS_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case UPDATEUSERS.UPDATEUSERS_SUCCESS:

        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: true

        };

      case UPDATEUSERS.UPDATEUSERS_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      //USERS DETAILS BY ID
      case USERSDETAILS.USERSDETAILS_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case USERSDETAILS.USERSDETAILS_SUCCESS:
        const usersData = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          userData: usersData,
          progress: true

        };

      case USERSDETAILS.USERSDETAILS_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };

      //ORGANIZATIONDROPDOWN BY ID
      case ORGANIZATIONDROPDOWN.ORGANIZATIONDROPDOWN_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case ORGANIZATIONDROPDOWN.ORGANIZATIONDROPDOWN_SUCCESS:
        const orgData = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          orgdwdnData: orgData,

        };

      case ORGANIZATIONDROPDOWN.ORGANIZATIONDROPDOWN_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      // SENDINVITATION
      case SENDINVITATION.SENDINVITATION_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case SENDINVITATION.SENDINVITATION_SUCCESS:
        const sendData = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          emailData: sendData,

        };

      case SENDINVITATION.SENDINVITATION_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      //REGISTER_USER
      case REGISTER_USER.REGISTER_USER_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case REGISTER_USER.REGISTER_USER_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false,

        };

      case REGISTER_USER.REGISTER_USER_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      //MODIFY_USER
      case MODIFY_USER.MODIFY_USER_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case MODIFY_USER.MODIFY_USER_SUCCESS:
        return {
          ...state,
          user: action.payload,
          isLoading: false
        };

      case MODIFY_USER.MODIFY_USER_ERROR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      //DELETE_ORG_USER
      case DELETE_ORG_USER.DELETE_ORG_USER_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case DELETE_ORG_USER.DELETE_ORG_USER_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false,

        };

      case DELETE_ORG_USER.DELETE_ORG_USER_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      //GET COMPANY PHONE NUMBER
      case COMPANY_PHNO.COMPANY_PHNO_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case COMPANY_PHNO.COMPANY_PHNO_SUCCESS:
        const PH = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          phnumb: PH,
          progress: true

        };

      case COMPANY_PHNO.COMPANY_PHNO_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };

      //forgot password
      case FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS:
        const resdata = action.payload;
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: true,
          resData: resdata
        };

      case FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      //RESET_PASSWORD
      case RESET_PASSWORD.RESET_PASSWORD_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case RESET_PASSWORD.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: true,
        };

      case RESET_PASSWORD.RESET_PASSWORD_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
        //NOTIFICATION_DETAILS
        case NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_INITLIZATION:
          return {
            ...state,
            ...action.payload,
            isLoading: true,
            progress: true,
          };
        case NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_SUCCESS:
          const NOTDATA = action.payload;
          return {
            ...state,
            ...action.payload,
            isLoading: false,
            Notdata: NOTDATA,
            progress: true
  
          };
  
        case NOTIFICATION_DETAILS.NOTIFICATION_DETAILS_ERORR:
          return {
            ...state,
            ...action.payload,
            isLoading: false,
            progress: false
          };
      //NOTIFICATION_UPLOAD 
      case NOTIFICATION_UPLOAD.NOTIFICATION_UPLOAD_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
        };
      case NOTIFICATION_UPLOAD.NOTIFICATION_UPLOAD_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: true,
        };

      case NOTIFICATION_UPLOAD.NOTIFICATION_UPLOAD_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          progress: false
        };
      case PROFILEIMG.PROFILEIMG_UPLOAD:
        return {
          ...state,
          profileImg: action.payload,
        };

      case LOGOIMG.LOGOIMG_UPLOAD:
        return {
          ...state,
          logoImg: action.payload,
        };

      case LOGOUT.LOGOUT:
        return {
          ...state,
          isLoggedout: true,
        };


      case UPDATECOMPANY.UPDATECOMPANY_INITIALITATION:
        return {
          ...state,
        };

      case UPDATECOMPANY.UPDATECOMPANY_SUCCESS:
        return {
          ...state,
        };

      case UPDATECOMPANY.UPDATECOMPANY_ERROR:
        return {
          ...state,
        };


      default:
        return state;
    }
  }
);

export default reducer;
