/* eslint-disable no-case-declarations */
import {
  INVOICELIST,
  INVOICEPDF,
  INVOICEFILTER,
  CREATEUSERS,
  INVOICECOUNT,
  INVOICESTATUS,
} from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState  = {
  progress: false,
  isLoading: false,
  invCount: 0,
  allInvoiceList:[],
  InvoicePDFDA:[],
  InvoiceFilterDA:{},
  statusList: {},
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] }, (state = initialState, action) => {
    switch (action.type) {
      case INVOICECOUNT.INVOICECOUNT_INITLIZATION:
        return {
          ...state,
          isLoading: true
        };
      case INVOICECOUNT.INVOICECOUNT_SUCCESS:
        const invCount = action.payload;
        return {
          ...state,
          isLoading: false,
          invCount: invCount
        };
      case INVOICECOUNT.INVOICECOUNT_ERORR:
        return {
          ...state,
          isLoading: false
        };
      case INVOICELIST.INVOICELIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true
        };

      case INVOICELIST.INVOICELIST_SUCCESS:
        const allInvoice = action.payload;
        return {
          ...state,
          isLoading: false,
          allInvoiceList: allInvoice
        };

      case INVOICELIST.INVOICELIST_ERROR:
        return {
          ...state,
          isLoading: false
        };
      case INVOICESTATUS.INVOICESTATUS_INITLIZATION:
        return {
          ...state,
          isLoading: true
        };

      case INVOICESTATUS.INVOICESTATUS_SUCCESS:
        const status = action.payload;
        // console.log(status,"111111");
        return {
          ...state,
          isLoading: false,
          statusList: status
        };

      case INVOICESTATUS.INVOICESTATUS_ERORR:
        return {
          ...state,
          isLoading: false
        };
        case INVOICEPDF.INVOICEPDF_INITIALIZATION:
        return {
          ...state,
          isLoading: true
        };

      case INVOICEPDF.INVOICEPDF_SUCCESS:
        const Invoicepdf = action.payload;
        return {
          ...state,
          isLoading: false,
          InvoicePDFDA: Invoicepdf
        };

      case INVOICEPDF.INVOICEPDF_ERROR:
        return {
          ...state,
          isLoading: false
        };

        case INVOICEFILTER.INVOICEFILTER_INITIALIZATION:
        return {
          ...state,
          isLoading: true
        };

      case INVOICEFILTER.INVOICEFILTER_SUCCESS:
        const Invoicefilter = action.payload;
        return {
          ...state,
          isLoading: false,
          InvoiceFilterDA: Invoicefilter
        };

      case INVOICEFILTER.INVOICEFILTER_ERROR:
        return {
          ...state,
          isLoading: false
        }; 

        case CREATEUSERS.CREATEUSERS_INITIALIZATION:
          return {
            ...state,
            isLoading: true
          };
  
        case CREATEUSERS.CREATEUSERS_SUCCESS:
          return {
            ...state,
            isLoading: false,
          };
  
        case CREATEUSERS.CREATEUSERS_ERROR:
          return {
            ...state,
            isLoading: false
          };
      default:
        return state;
    }
  }
);
export default reducer;
