import axios from './axiosConfig';
import {toast} from "react-toastify";

export const METHOD = {
    POST: 'post',
    GET: 'get',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch'
};

export const apiCall = async (
    endpoint,
    data = {},
    onSuccess,
    onFailure,
    method = METHOD.POST,
    DynamicConfig = {}
) => {
    try {
        let response;

        switch (method) {
            case METHOD.POST:
                response = await axios.post(endpoint, data, { ...DynamicConfig });
                break;
            case METHOD.GET:
                response = await axios.get(endpoint, { ...DynamicConfig });
                break;
            case METHOD.DELETE:
                response = await axios.delete(endpoint, { ...DynamicConfig });
                break;
            case METHOD.PUT:
                response = await axios.put(endpoint, data, { ...DynamicConfig });
                break;
            case METHOD.PATCH:
                response = await axios.patch(endpoint, data, { ...DynamicConfig });
                break;
            default:
                toast.error('Se ha producido un error al contactar con la API', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    theme: 'light',
                });
        }

        if (response && (response.status === 200 || response.status === 201 || response.status === 204 || response.data)) {
            onSuccess(response);
        } else {
            console.log(response);
            onFailure('Something went wrong 1');
        }
    } catch (error) {
        console.error('error', error);
        if (error.response) {
            console.error('error', error);
            switch (error.response.status) {
                case 401:
                    onFailure(
                        error.response.data && typeof error.response.data.detail
                            ? error.response.data.detail
                            : 'Session expired'
                    );
                    break;
                default:
                    onFailure(error.response.data ? error.response.data : 'Something went wrong 2');
                    break;
            }
        } else {
            console.error('error', error);
            onFailure && onFailure('Something went wrong 3 => ', error);
        }
    }
};
