import React, {useEffect} from "react";
import {useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {dispatch} from "../redux";
import {useSelector} from "react-redux";
import {notificationUpload, notificationDetails} from "../redux/action";
import {useFormik} from "formik";
import {notificationUploadSchema} from "../validationSchema/validationSchema";

export const UploadNotifications = (Props) => {
    const [show, setShow] = useState(Props.showData);
    const [noteData, setNoteData] = useState({});
    const notificationData = useSelector((state) => state?.userDataReducer?.Notdata);

    //console.log('NOTIFICATION DATA', notificationData);
    const {t} = useTranslation()

    const handleClose = () => {
        setShow(false)
        Props.parentCallback(false);
    }

    useEffect(() => {
        dispatch(notificationDetails(Props.id));
    }, [Props.id]);

    useEffect(() => {
        if (notificationData) {
            setNoteData(notificationData);
        }
    }, [notificationData]);
    const handleConfirm = () => {
        setShow(false);
        Props.parentCallback(false);
    }
    useEffect(() => {
        setShow(true);
    }, [Props.showData])

    const formik = useFormik({
        initialValues: {
            id: noteData.id,
            shipmentId: noteData.shipmentId,
            reference: noteData.reference,
            status: noteData.PENDING,
            responsable: noteData.responsable,
            creationDate: noteData.creationDate,
            summary: "",
            description: ""
        },
        validationSchema: notificationUploadSchema,
        onSubmit: async (values) => {
            await dispatch(
                notificationUpload(noteData.id, noteData.shipmentId,
                                        noteData.reference, noteData.status,
                                        noteData.responsable, noteData.creationDate,
                                        values.description, values.summary));
            handleConfirm();
        },
    });

  const { errors, touched } = formik;
  return (
    <>
      {(Props.showData === true) ?
        <Modal centered scrollable size="lg" show={show} onHide={handleClose} className="upload-notifications warning-modal" animation={false}>
          <Modal.Header className="align-items-center">
            <h3 className="modal-title">{t('Upload_File')}</h3>
          </Modal.Header>
          <Modal.Body>
            <Card className="rounded-0 shadow-none m-0">
              <Card.Header className='d-flex align-items-center h-auto rounded-0 p-0 mb-3'>
                <h4 className='font-weight-normal m-0'>{noteData.reference}</h4>
                <div className="ml-auto">
                  <h4 className='font-weight-normal text-uppercase m-0'>{noteData.status}</h4>
                </div>
              </Card.Header>
              <Card.Body className="common-details text-dark p-0">
                <p className="font-weight-bold mb-4">{t('Upload_message')}</p>
                <Form.Group className="arrow-down mb-4">
                  <Form.Label className="font-size-13 font-weight-bold">{t('Reference')}</Form.Label>
                  <Form.Control type="text" placeholder={t('Reference')} value={noteData.reference} readOnly />
                </Form.Group>
                <Form.Group className="arrow-down mb-4">
                  <Form.Label className="font-size-13 font-weight-bold">{t('Date')}</Form.Label>
                  <Form.Control type="text" placeholder={t('Date')}  value={noteData.creationDate} readOnly />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label className="font-size-13 font-weight-bold">{t('Summary')}</Form.Label>
                  <Form.Control type="text" placeholder={t('Summary')} onChange={formik.handleChange}
                                defaultValue={noteData.summary} onBlur={formik.handleBlur}
                    value={formik.values.summary}
                    name="summary"/>
                  {errors.summary && touched.summary && (
                    <small className="text-danger" id="summary" value={noteData.description}  >{errors.summary}</small>
                  )}
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label className="font-size-13 font-weight-bold">{t('Remarks')}</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={formik.handleChange}
                  defaultValue={noteData.remarks} onBlur={formik.handleBlur}
                    value={formik.values.remarks}
                    name="remarks" />
                  {errors.remarks && touched.remarks && (
                    <small className="text-danger" id="descriptation" value={noteData.remarks}  >{errors.remarks}</small>
                  )}
                </Form.Group>
                <Form.Group className="mb-0 drag-drop-attachment">
                  <Form.Label className="font-size-13 font-weight-bold d-block">{t('Attachment')}</Form.Label>
                  <div className="fileinput text-center fileinput-new position-relative d-block" data-provides="fileinput">
                    <div className="btn-file">
                      <div className="thumbnail fileinput-new uploaded-user-image font-size-15 text-primary-custom font-weight-bold" style={{ height: '104px', width: '100%' }}>
                        {t('Attachment_message')} <br />
                        <Button type="button" variant="secondary" className="fileinput-new btn-file text-primary-custom mt-2">{t('Browse')}</Button>
                      </div>
                      <div className="clearfix"></div>
                      <input type="hidden" value="" name="..." />
                      <input type="hidden" value="" name="Users[image]" />
                      <input type="file" file-model="myFile" accept="" onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.attachment}
                        name="attachment" />
                      {errors.attachment && touched.attachment && (
                        <small className="text-danger" id="attachment" >{errors.attachment}</small>
                      )}
                      <div className="fileinput-preview fileinput-exists thumbnail uploaded-user-image" height="104"></div>
                    </div>
                    <div className="text-center">
                      <button className="btn btn-link btn-sm fileinput-exists close-icon" data-dismiss="fileinput">
                        <i className="zmdi zmdi-close text-white m-0"></i>
                      </button>
                    </div>
                  </div>
                </Form.Group>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <Button variant="primary" onClick={() => {
              formik.handleSubmit();
            }}>
              {t('Send')}
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </Modal.Footer>
        </Modal>
        : null}
    </>
  )

}