import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {getToken, validateToken} from "../../utils";
// Define a service using a base URL and expected endpoints

export const customerWebsiteAPI = createApi({
            reducerPath: 'customerWebsiteAPI',

            baseQuery: fetchBaseQuery({
                baseUrl: process.env.REACT_APP_BASE_API_CALL,
                prepareHeaders: (headers, {getState}) => {
                    if (validateToken()) {
                        const token = getToken("login-auth-token");
                        // const validatedToke = valiadteToken();
                        const tk = token.replace(/"/g, ' ');
                        const auth = tk ? `Bearer ${tk}` : '';
                        headers.set('authorization', auth);
                    }
                    return headers
                },
            }),
            tagTypes: ['UserProfile', 'CompanyProfile', 'Quotations', 'Notifications', 'Theme'],
            endpoints: (builder) => ({
                getThemeSettings: builder.query({
                    query: () => ({
                        url: "settings/theme",
                    }),
                    providesTags: ['Theme']
                }),
                fetchPendingNotifications: builder.query({
                    query: () => ({
                        url: `header/pendingnotifications`,
                    }),
                    providesTags: ['Notifications']
                }),
                fetchUserProfile: builder.query({
                    query: () => ({
                        url: `settings/myprofile`,
                    }),
                    providesTags: ['UserProfile']
                }),
                fetchQuotation: builder.query({
                    query: (quotationId) => ({
                        url: `quotation/${quotationId}`,
                    }),
                    providesTags: ['Quotations']
                }),
                acceptQuotation: builder.mutation({
                    query: (data) => ({
                        url: `quotation/accept`,
                        method: 'POST',
                        body: {
                            "quotationId": data.quotationId,
                            "message": data.message
                        },
                        invalidateTags: ['Quotations']
                    }),

                }),
                rejectQuotation: builder.mutation({
                    query: (quotationId, message) => ({
                        url: `quotation/reject`,
                        method: 'POST',
                        body: {
                            quotationId,
                            "message": message
                        },
                        invalidateTags: ['Quotations']
                    }),

                }),
                fetchCompanyProfile: builder.query({
                    query: () => ({
                        url: `settings/mycompany`,
                    }),
                    providesTags: ['CompanyProfile']
                }),
                updateCompanyProfile: builder.mutation({
                    query: (company) => ({
                        url: `settings/modifycompany`,
                        method: 'POST',
                        body: {
                            "id": company.id,
                            "colorCode": company.colorCode,
                            "timeZone": company.timeZone
                        },
                        invalidateTags: ['CompanyProfile']
                    }),
                }),
                downloadAttachment: builder.mutation({
                    query: (attachment) => ({
                        url: `download-attachment/${attachment.scope}/${attachment.id}`,
                    }),
                    invalidatesTags: ['Attachments'],

                }),
                recoveryPassword: builder.mutation({
                    query: (email) => ({
                        url: `recovery/`,
                        method: 'POST',
                        body: {
                            "user": email
                        },
                    }),
                    invalidatesTags: ['recoveryPassword'],

                }),
                fetchShipment: builder.query({
                    query: (shipmentId) => ({
                        url: `shipment/${shipmentId}`,
                    }),
                    providesTags: ['Shipments']
                }),
            }),
        }
    )
;

export const {
    useGetThemeSettingsQuery,
    useFetchQuotationQuery,
    useAcceptQuotationMutation,
    useRejectQuotationMutation,
    useFetchPendingNotificationsQuery,
    useFetchUserProfileQuery,
    useFetchCompanyProfileQuery,
    useUpdateCompanyProfileMutation,
    useDownloadAttachmentMutation,
    useFetchShipmentQuery,
    useRecoveryPasswordMutation
} = customerWebsiteAPI;