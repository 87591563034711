import React from 'react';
import "./WIPStyle.css";
export const Wip = () => {
    return (
        <>
            <div className="overlay"></div>
            <div className="stars" aria-hidden="true"></div>
            <div className="starts2" aria-hidden="true"></div>
            <div className="stars3" aria-hidden="true"></div>
            <main className="main">
                <section className="contact">
                    <h1 className="title">Awesome Thing</h1>
                    <h2 className="sub-title">This Page is Under Construction</h2>
                </section>
            </main>
        </>
    )

}