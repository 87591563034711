import React, { useEffect, useState, useRef } from "react";
import { Card, Breadcrumb, Button, Row, Col, Table, Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';

import DatePicker from "react-multi-date-picker";
// import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { ReactComponent as DashFilter1 } from "../../assets/img/dash-filter-1.svg";
import { ReactComponent as DashFilter2 } from "../../assets/img/dash-filter-2.svg";
import { ReactComponent as DashFilter3 } from "../../assets/img/dash-filter-3.svg";
import { ReactComponent as DashFilter4 } from "../../assets/img/dash-filter-4.svg";
// import { ReactComponent as DashFilter5 } from "../../assets/img/dash-filter-5.svg";
import { ReactComponent as DashFilter6 } from "../../assets/img/dash-filter-6.svg";

import { ReactComponent as BackIcon } from '../../assets/img/back.svg'
import { ReactComponent as SearchIcon } from '../../assets/img/search-icon.svg'
import { ReactComponent as SortIcon } from '../../assets/img/sort.svg'
import { ReactComponent as SortPrimaryIcon } from '../../assets/img/sort-primary.svg'
import { ReactComponent as AcceptedIcon } from '../../assets/img/accepted.svg'
import { ReactComponent as MissingInfoIcon } from '../../assets/img/missing-info.svg'
import { ReactComponent as InProgressIcon } from '../../assets/img/in-progress.svg'
import { ReactComponent as RejectedIcon } from '../../assets/img/rejected.svg'
import { ReactComponent as ArrowDownIcon } from "../../assets/img/arrow-down.svg";
import { NeedHelpBox } from "../../components";
import { FaEye } from 'react-icons/fa';
//redux
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import {quotationCount, quotationList, quotationStatus} from "../../redux/action";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

import { useHistory } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import {resetQuotationFilters, setQuotationFilters} from "../../redux/reducers/quotationFiltersReducer";
// import Loader from "react-js-loader";

const Quotation = () => {
    const filters = useSelector((state) => state?.quotationFiltersReducer?.quotationFilters)
    const sessionsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState(false);
    const [countData, setCountData] = useState(null);
    const [listData, setListData] = useState([]);
    const [searchtext, setsearchText] = useState('');
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [status, setStatus] = useState("");
    const [statusFilter, setStatusFilter] = useState('');
    const [fromRecord, setFromRecord] = useState(0);

    const QuotationCount = useSelector((state) => state?.quotationReducer?.quotCount);
    const quotationListData = useSelector((state) => state?.quotationReducer?.allQuotationList);
    const quotationFilterData = useSelector((state) => state?.quotationReducer?.QuotationFilterDA);
    const QuotationStatusData = useSelector((state) => state?.quotationReducer?.statusList);

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const { t } = useTranslation()
    const history = useHistory()

    const pagesCount = Math.ceil(countData / 5);
    const isPaginationShown = true;
    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === pagesCount;
    let isPageNumberOutOfRange;

    const changePage = number => {
        if (currentPage === number) return;
        setCurrentPage(number);
        dispatch(setQuotationFilters({ ...filters, fromrecord: (currentPage - 1) * sessionsPerPage}))
    };

    const onPageNumberClick = pageNumber => {
        changePage(pageNumber);
        dispatch(setQuotationFilters({ ...filters, fromrecord: (pageNumber - 1) * sessionsPerPage}))
    };

    const onPreviousPageClick = () => {
        changePage(currentPage => currentPage - 1);
        dispatch(setQuotationFilters({ ...filters, fromrecord: (currentPage - 2) * sessionsPerPage}))
    };

    const onNextPageClick = () => {
        changePage(currentPage => currentPage + 1);
        dispatch(setQuotationFilters({ ...filters, fromrecord: currentPage * sessionsPerPage}))
    };

    const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === pagesCount;
        const isCurrentPageWithinTwoPageNumbers =
            Math.abs(pageNumber - currentPage) <= 2;

        if (
            isPageNumberFirst ||
            isPageNumberLast ||
            isCurrentPageWithinTwoPageNumbers
        ) {
            isPageNumberOutOfRange = false;
            return (
                <Pagination.Item
                    key={pageNumber}
                    onClick={() => onPageNumberClick(pageNumber)}
                    active={pageNumber === currentPage}
                >
                    {pageNumber}
                </Pagination.Item>
            );
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    useEffect(() => {
        setCountData(QuotationCount);
    }, [QuotationCount]);

    useEffect(() => {
        dispatch(quotationCount(filters));
        dispatch(quotationList(filters));
        dispatch(quotationStatus(filters));
    }, [filters]);

    useEffect(() => {
        if (quotationFilterData) {
            dispatch(setQuotationFilters({ ...filters, quotationFilterData: quotationFilterData, fromrecord: 0 }))
        }
    }, [quotationFilterData])

    useEffect(() => {
        if (QuotationStatusData) {
            setStatus(QuotationStatusData)
        }
    }, [QuotationStatusData])

    useEffect(() => {
        if (fromDate) {
            let date = JSON.stringify(fromDate)
            dispatch(setQuotationFilters({ ...filters, fromdate: date, fromrecord: 0 }))
            setCurrentPage(1);
        }
    }, [fromDate]);

    useEffect(() => {
        if (toDate) {
            let date = JSON.stringify(toDate)
            dispatch(setQuotationFilters({ ...filters, todate: date, fromrecord: 0 }))
            setCurrentPage(1);
        }
    }, [toDate]);

    useEffect(() => {
        if (statusFilter) {
            dispatch(setQuotationFilters({ ...filters, status: statusFilter, fromrecord: 0 }))
            setCurrentPage(1);
        }
    }, [statusFilter]);

    useEffect(() => {
        if (quotationListData) {
            setListData(quotationListData);
        }
    }, [quotationListData]);

    useEffect(() => {
        document.body.dir = currentLanguageCode.dir || 'ltr'
    }, [currentLanguageCode, t])

    const handleSort = (sortName) => {
        setSortType(!sortType)

        if (sortType === false) {

            listData.sort(function (a, b) {
                if (a[sortName] < b[sortName]) return 1;
                if (a[sortName] > b[sortName]) return -1;
                return 0;
            });
            return listData;
        } else {

            listData.sort(function (a, b) {
                if (a[sortName] < b[sortName]) return -1;
                if (a[sortName] > b[sortName]) return 1;
                return 0;
            });
            return listData;
        }
    }

    const handlePreview = (url,id) => {
        history.push({
            pathname: "/quotations/info",
            url: url,
            id:id
        })
    }
    //Date Handlers
    const startRefTo = useRef();
    const startRefFrom = useRef();

    const handleKeyDownTo = () => {
        if (startRefFrom?.current?.getElementsByClassName("rmdp-calendar")?.length !== 0) {
            const valRef = startRefFrom?.current?.getElementsByClassName("rmdp-calendar");
            valRef[0].classList.add("HIDE");
        }
    };

    const handleKey = () => {
        if (startRefTo?.current?.getElementsByClassName("rmdp-calendar")?.length !== 0) {
            const valRefTo = startRefTo?.current?.getElementsByClassName("rmdp-calendar");
            valRefTo[0].classList.add("HIDE");
        }

    }
    const handleOpenFrom = () => {
        if (startRefFrom?.current?.getElementsByClassName("HIDE")) {
            const valRefHide = startRefFrom?.current?.getElementsByClassName("rmdp-calendar");
            valRefHide[0].classList.remove("HIDE");
        }
    }

    const handleOpenTo = () => {
        if (startRefTo?.current?.getElementsByClassName("HIDE")) {
            const valRefHideTo = startRefTo?.current?.getElementsByClassName("rmdp-calendar");
            valRefHideTo[0].classList.remove("HIDE");
        }
    }

    const handleSearch = (text) => {
        setsearchText(text);
        dispatch(setQuotationFilters({ ...filters, text: text }))
        setCurrentPage(1);
    }

    const handleRefresh = () => {
        dispatch(resetQuotationFilters());
        setStatusFilter('')
        setSortType('');
        setsearchText('');
        setFromDate('');
        setToDate('');
        setCurrentPage(1);
        setFromRecord(0);
    }

    const handleStatus = (e) => {
        setStatusFilter(e)
    }

    return (
        <React.Fragment>
            <div className="content">
                <Breadcrumb className='hot-shipment-action'>
                    <Breadcrumb.Item className='mr-3' onClick={history.goBack}> <BackIcon className="back-icon" /> </Breadcrumb.Item>

                </Breadcrumb>
                <section className="page-content container-fluid">
                    <h1 className="page-title">{t('Quotations')}</h1>

                    <Row className='form-row'>
                        <Col className='col-lg-10'>
                            <Card>
                                <Card.Body>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="options"
                                        defaultValue=""
                                        value={statusFilter}
                                        onChange={(e) => handleStatus(e)}
                                        className="dashboard-filter invoice-filter d-flex"
                                    >
                                        <ToggleButton id="tbg-radio-1" variant="" value="ACCEPTED" className="flex-fill">
                                            <div className="d-flex align-items-center">
                                                <div className="icon ml-2">
                                                    <AcceptedIcon />
                                                </div>
                                                <div className="d-flex flex-column flex-fill text-center ml-3">
                                                    <h2 className='font-size-60 lh-58 mb-0'>{status.accepted}</h2>
                                                    <p className="font-size-16 text-primary-custom font-weight-bold m-0">{t('Quotations')}</p>
                                                </div>
                                            </div>
                                            <div className="my-3">
                                                <ProgressBar variant="primary" now={75} />
                                            </div>
                                            <div className="text-center">
                                                <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Accepted')}</p>
                                                {/* <h4 className="h2 m-0">${filterType.accepted}</h4> */}
                                            </div>
                                        </ToggleButton>
                                        <ToggleButton id="tbg-radio-3" variant="" value='PENDING'>
                                            <div className="d-flex align-items-center">
                                                <div className="icon ml-2">
                                                    <InProgressIcon />
                                                </div>
                                                <div className="d-flex flex-column flex-fill text-center ml-3">
                                                    <h2 className='font-size-60 lh-58 mb-0'>{status.pending}</h2>
                                                    <p className="font-size-16 text-primary-custom font-weight-bold m-0">{t('Quotations')}</p>
                                                </div>
                                            </div>
                                            <div className="my-3">
                                                <ProgressBar variant="primary" now={50} />
                                            </div>
                                            <div className="text-center">
                                                <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Pending')}</p>
                                                {/* <h4 className="h2 m-0">${filterType.inProgress}</h4> */}
                                            </div>
                                        </ToggleButton>
                                        <ToggleButton id="tbg-radio-4" variant="" value='REJECTED'>
                                            <div className="d-flex align-items-center">
                                                <div className="icon ml-2">
                                                    <RejectedIcon />
                                                </div>
                                                <div className="d-flex flex-column flex-fill text-center ml-3">
                                                    <h2 className='font-size-60 lh-58 mb-0'>{status.rejected}</h2>
                                                    <p className="font-size-16 text-primary-custom font-weight-bold m-0">{t('Quotations')}</p>
                                                </div>
                                            </div>
                                            <div className="my-3">
                                                <ProgressBar variant="primary" now={20} />
                                            </div>
                                            <div className="text-center">
                                                <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Rejected')}</p>
                                                {/* <h4 className="h2 m-0">${filterType.rejected}</h4> */}
                                            </div>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    {/*<Card.Header className='border-0 px-0'>*/}
                                    {/*    <h3 className='mb-0'>Quotations List</h3>*/}
                                    {/*</Card.Header>*/}
                                    <Card.Body className='p-0'>
                                        <Card className='mb-0'>
                                            <div className='card-body'>
                                                <Row className='mb-3 align-items-center'>
                                                    <Col lg={8}>
                                                        <div className='table-top-select'>
                                                            <div className='arrow-down d-inline-block mr-2 mr-lg-4'>
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div className="date-picker mb-2 mb-sm-0 mr-2 mr-lg-3" onClick={handleOpenFrom}>
                                                                        <span className="icon">
                                                                            <i className="zmdi zmdi-calendar"></i>
                                                                        </span>
                                                                        <DatePicker value={fromDate} onChange={setFromDate} inputClass="form-control" placeholder={t('From')} format="DD/MM/YYYY" ref={startRefFrom} />
                                                                    </div>
                                                                    <div className="date-picker mb-2 mb-sm-0" onClick={handleOpenTo}>
                                                                        <span className="icon">
                                                                            <i className="zmdi zmdi-calendar"></i>
                                                                        </span>
                                                                        <DatePicker value={toDate} onChange={setToDate} ref={startRefTo} onOpen={(e) => handleKeyDownTo(e)} inputClass="form-control" placeholder={t('To')} format="DD/MM/YYYY" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col lg={4} className='d-flex justify-content-end mt-2 mt-lg-0'>
                                                        <div className="table-top-search d-flex mr-3">
                                                            <SearchIcon className="icon" />
                                                            <Form.Control type="text" placeholder={t('Search')} value={searchtext} onChange={(e) => handleSearch(e.target.value)} onFocus={handleKey} />
                                                        </div>
                                                        <span className="refresh-icon" onClick={handleRefresh}>
                                                            <i className="zmdi zmdi-refresh font-size-18 align-middle"></i>
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Table responsive borderless hover>
                                                    <thead>
                                                    <tr>
                                                        <th className="text-primary-custom" onClick={() => handleSort("transportMode")} >{t('Transport')} <SortPrimaryIcon className='sort-primary-icon align-baseline' /></th>
                                                        <th className="text-primary-custom" onClick={() => handleSort("id")} ># <SortPrimaryIcon className='sort-primary-icon align-baseline' /></th>
                                                        <th onClick={() => handleSort("validFrom")} >{t('Valid_from')}  <SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("validUntil")} >{t('Valid_to')} <SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("origin")} >{t('Origin')} <SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("destination")} >{t('Destination')} <SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("trafficFlow")} >{t('Flow')} <SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("trafficChargeType")} >{t('Charge_type')} <SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("pricer")} >{t('Pricer')}<SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("reference")} >{t('Reference')} <SortIcon className='align-baseline' /></th>
                                                        <th onClick={() => handleSort("status")} >{t('Status')} <SortIcon className='align-baseline' /></th>
                                                        {/*<th onClick={() => handleSort("totalAmount")} >{t('Total_amount')} <SortIcon className='align-baseline' /></th>*/}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listData &&
                                                        listData?.map((item, index) => (
                                                            <tr key={item?.id} >
                                                                <td className="primary-stroke"> {(item?.transportMode === "SEA") ? <DashFilter1 className="w-60" /> : (item?.transportMode === "AIR") ? <DashFilter2 className="w-60" /> : (item?.transportMode === "LAND") ? <DashFilter3 className="w-60" /> : (item?.transportMode === "COUR") ? <DashFilter4 className="w-60" /> : <DashFilter6 className="w-60" />}</td>
                                                                <td>{item.id}</td>
                                                                <td>{item.validFrom}</td>
                                                                <td>{item.validUntil}</td>
                                                                <td>{item.origin}</td>
                                                                <td>{item.destination}</td>
                                                                <td>{item.trafficFlow}</td>
                                                                <td>{item?.transportMode === "SEA"?item.trafficChargeType:""}</td>
                                                                <td>{item.pricer}</td>
                                                                <td>{item.customerReference}</td>
                                                                <td>{item.status}</td>
                                                                {/*<td>{item.totalAmount}</td>*/}

                                                                <td className="actions-dropdown" onClick={() => handlePreview(item.url,item.id)}>
                                                                    <FaEye className="iconPreview" />

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card>
                                        <>
                                            {isPaginationShown && QuotationCount > 0 && (
                                                <Pagination size="sm" className='pt-2 mt-4 justify-content-end'>
                                                    <Pagination.Prev
                                                        onClick={onPreviousPageClick}
                                                        disabled={isCurrentPageFirst}
                                                    />
                                                    {pageNumbers}
                                                    <Pagination.Next
                                                        onClick={onNextPageClick}
                                                        disabled={isCurrentPageLast}
                                                    />
                                                </Pagination>
                                            )}
                                        </>
                                    </Card.Body>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='form-row'>
                        <Col xl={10}>
                            <Row className='form-row'>
                                <Col md={4} className="ml-auto">
                                    <NeedHelpBox />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </div>
        </React.Fragment>
    )
}

export default Quotation;
