import {createSlice} from '@reduxjs/toolkit'


const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        invoiceFilters: {
            status: '',
            fromdate: '',
            todate: '',
            type: '',
            text: '',
            fromrecord: 0,
            numberofrecords: 5
        }
    },
    reducers: {
        setInvoiceFilters: (state, action) => {
            state.invoiceFilters = action.payload;
        },
        resetInvoiceFilters: (state , action) => {
            state.invoiceFilters = {
                status: '',
                fromdate: '',
                todate: '',
                type: '',
                text: '',
                fromrecord: 0,
                numberofrecords: 5
                }
            }
        }
    });

export const {setInvoiceFilters, resetInvoiceFilters} = filtersSlice.actions
export default filtersSlice.reducer