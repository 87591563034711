/* eslint-disable no-case-declarations */
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {COMPANY_THEME_SETTINGS} from "../constants/company-action-types";

const initialState = {
    isLoading: false,
    logoImg: "",
    themeSettings: {
        colorCode: "#643489"
    }
};

const reducer = persistReducer(
    {storage, key: "basecode-demo", whitelist: ["authToken"]}, (state = initialState, action) => {

        switch (action.type) {
            case COMPANY_THEME_SETTINGS.THEME_SETTINGS_INITIALIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case COMPANY_THEME_SETTINGS.THEME_SETTINGS_SUCCESS:

                return {
                    ...state,
                    isLoading: false,
                    themeSettings:{
                        colorCode: action.payload.colorCode
                    }
                };

            case COMPANY_THEME_SETTINGS.THEME_SETTINGS_ERROR:
                return {
                    ...state,
                    isLoading: false
                };

            //company user profile
            default:
                return state;
        }
    }
);

export default reducer;
