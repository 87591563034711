/* eslint-disable no-case-declarations */
import {
  QUOTATIONLIST,
  QUOTATIONFILTER,
  QUOTATIONCOUNT,
  QUOTATIONSTATUS
} from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState  = {
  progress: false,
  isLoading: false,
  quotCount: 0,
  allQuotationList:[],
  QuotationFilterDA:{},
  statusList: {}
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] }, (state = initialState, action) => {
    switch (action.type) {
      case QUOTATIONCOUNT.QUOTATIONCOUNT_INITLIZATION:
        return {
          ...state,
          isLoading: true
        };
      case QUOTATIONCOUNT.QUOTATIONCOUNT_SUCCESS:
        const quotCount = action.payload;
        return {
          ...state,
          isLoading: false,
          quotCount: quotCount
        };
      case QUOTATIONCOUNT.QUOTATIONCOUNT_ERORR:
        return {
          ...state,
          isLoading: false
        };
        case QUOTATIONLIST.QUOTATIONLIST_INITLIZATION:
          return {
            ...state,
            isLoading: true
          };
  
        case QUOTATIONLIST.QUOTATIONLIST_SUCCESS:
          const allQuotation = action.payload;
          return {
            ...state,
            isLoading: false,
            allQuotationList: allQuotation
          };
  
        case QUOTATIONLIST.QUOTATIONLIST_ERROR:
          return {
            ...state,
            isLoading: false
          };

        //filter type
        case QUOTATIONFILTER.QUOTATIONFILTER_INITIALIZATION:
        return {
          ...state,
          isLoading: true
        };

      case QUOTATIONFILTER.QUOTATIONFILTER_SUCCESS:
        const Quotationfilter = action.payload;
        return {
          ...state,
          isLoading: false,
          QuotationFilterDA: Quotationfilter
        };

      case QUOTATIONFILTER.QUOTATIONFILTER_ERROR:
        return {
          ...state,
          isLoading: false
        };
      case QUOTATIONSTATUS.QUOTATIONSTATUS_INITLIZATION:
        return {
          ...state,
          isLoading: true
        };

      case QUOTATIONSTATUS.QUOTATIONSTATUS_SUCCESS:
        const status = action.payload;
        // console.log(status,"111111");
        return {
          ...state,
          isLoading: false,
          statusList: status
        };

      case QUOTATIONSTATUS.QUOTATIONSTATUS_ERORR:
        return {
          ...state,
          isLoading: false
        };
      default:
        return state;
    }
  }
);
export default reducer;
